import * as React from 'react'
import { Tooltip, IconButton,  Menu, MenuItem, ListItemIcon, Typography, Fade } from '@mui/material'

export default function PakMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickBurnArt = () => {
    handleClose()
    window.open('https://burn.art')
  }

  const handleClickMassBlack = () => {
    handleClose()
    window.open('https://mass.black/')
  }

  const handleClickLostPoets = () => {
    handleClose()
    window.open('https://lostpoets.xyz/')
  }

  return (
    <React.Fragment>
      <Tooltip
        title={
          <Typography
            variant="subtitle2"
            onClick={() => window.open('https://twitter.com/muratpak')}
            sx={{ cursor: 'pointer' }}
          >
            Pak
          </Typography>
        }
        placement="top"
        TransitionComponent={Fade}
        enterDelay={800}
        leaveDelay={200}
      >
        <IconButton
          id="pak-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M10.2195 20.4282C15.7423 20.4282 20.2195 15.9511 20.2195 10.4282C20.2195 4.90538 15.7423 0.428223 10.2195 0.428223C4.69663 0.428223 0.219482 4.90538 0.219482 10.4282C0.219482 15.9511 4.69663 20.4282 10.2195 20.4282ZM10.1902 15.4027C12.9355 15.4027 15.161 13.1772 15.161 10.4319C15.161 7.68667 12.9355 5.46118 10.1902 5.46118C7.44497 5.46118 5.21948 7.68667 5.21948 10.4319C5.21948 13.1772 7.44497 15.4027 10.1902 15.4027Z" fill="white"/>
          </svg>
        </IconButton>
      </Tooltip>
      <Menu
        id="pak-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'icon-button',
        }}
      >
        <MenuItem onClick={handleClickBurnArt}>
          <ListItemIcon>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.919922 6.65674L6.91992 0.656738L12.9199 6.65674L18.9199 0.656738L24.9199 6.65674L18.9199 12.6567L24.9199 18.6567L18.9199 24.6567L12.9199 18.6567L6.91992 24.6567L0.919922 18.6567L6.91992 12.6567L0.919922 6.65674Z" fill="white"/>
            </svg>
          </ListItemIcon>
          <Typography variant="subtitle2">Burn</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickLostPoets}>
          <ListItemIcon>
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.35333 21.17C6.47075 21.169 6.58397 21.2136 6.66915 21.2945C6.75433 21.3753 6.80482 21.486 6.81 21.6033V23.5433C6.80585 23.6631 6.75641 23.7769 6.67165 23.8616C6.58689 23.9464 6.47313 23.9959 6.35333 24H0.456667C0.336871 23.9959 0.223111 23.9464 0.138353 23.8616C0.0535936 23.7769 0.00414885 23.6631 0 23.5433L0 0.456667C0.00414885 0.336871 0.0535936 0.223111 0.138353 0.138353C0.223111 0.0535936 0.336871 0.00414885 0.456667 0L6.35333 0C6.47313 0.00414885 6.58689 0.0535936 6.67165 0.138353C6.75641 0.223111 6.80585 0.336871 6.81 0.456667V2.37333C6.81 2.49445 6.76189 2.6106 6.67624 2.69625C6.5906 2.78189 6.47445 2.83 6.35333 2.83H3.31V21.1633L6.35333 21.17Z" fill="white"/>
              <path d="M17.4567 2.83C17.3392 2.83098 17.226 2.78636 17.1408 2.70553C17.0557 2.6247 17.0052 2.51398 17 2.39667V0.456667C17.0041 0.336871 17.0536 0.223111 17.1383 0.138353C17.2231 0.0535936 17.3369 0.00414885 17.4567 0L23.3533 0C23.4731 0.00414885 23.5869 0.0535936 23.6717 0.138353C23.7564 0.223111 23.8058 0.336871 23.81 0.456667V23.5433C23.8058 23.6631 23.7564 23.7769 23.6717 23.8616C23.5869 23.9464 23.4731 23.9959 23.3533 24H17.4567C17.3369 23.9959 17.2231 23.9464 17.1383 23.8616C17.0536 23.7769 17.0041 23.6631 17 23.5433V21.6267C17 21.5056 17.0481 21.3894 17.1337 21.3038C17.2194 21.2181 17.3355 21.17 17.4567 21.17H20.5V2.83667L17.4567 2.83Z" fill="white"/>
            </svg>
          </ListItemIcon>
          <Typography variant="subtitle2">LOST POETS ∴ AB AETERNO</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickMassBlack}>
          <ListItemIcon>
            <svg viewBox="0 0 24 24" height="16">
              <circle cx="12" cy="12" r="12" fill="white"></circle>
            </svg>
          </ListItemIcon>
          <Typography variant="subtitle2">m</Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}