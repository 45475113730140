import * as React from "react"
import { IconButton, Menu, MenuItem, ListItemIcon, Typography } from "@mui/material"
import { Hive } from "@mui/icons-material"

export default function DeFiMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickChainlink = () => {
    handleClose()
    window.open(`https://chainlinklabs.com/`)
  }

  const handleClickBancor = () => {
    handleClose()
    window.open(`https://app.bancor.network/portfolio`)
  }

  return (
    <div>
      <IconButton
        id="defi-button"
        title="DeFi"
        aria-controls={open ? `basic-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? `true` : undefined}
        onClick={handleClick}
      >
        <Hive />
      </IconButton>
      <Menu
        id="defi-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": `icon-button`,
        }}
      >
        <MenuItem onClick={handleClickChainlink}>
          <ListItemIcon>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                fill="#375BD2"
              />
              <path
                d="M12 3L4.20569 7.5V16.5L12 21L19.7943 16.5V7.5L12 3ZM16.4913 14.5932L12 17.1861L7.50869 14.5932V9.4068L12 6.8139L16.4913 9.4068V14.5932Z"
                fill="white"
              />
            </svg>
          </ListItemIcon>
          <Typography variant="subtitle2">Chainlink Labs</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickBancor}>
          <ListItemIcon>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1209_20)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.0681 0L16.7459 3.23102L11.0681 6.46207L5.39026 3.23102L11.0681 0ZM18.008 5.14055V11.6054L12.2182 14.8971V8.43504L18.008 5.14055ZM19.8857 13.1811L12.2182 17.5352V24L19.8857 19.646V13.1811ZM4.11426 11.6025V5.14046L9.90122 8.43497V14.897L4.11426 11.6025ZM4.11426 14.2398V20.7046L9.90122 23.9964V17.5343L4.11426 14.2398Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1209_20">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </ListItemIcon>
          <Typography variant="subtitle2">Bancor</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}
