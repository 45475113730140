import * as React from 'react'
import { IconButton,  Menu, MenuItem, Typography, Divider } from '@mui/material'
import { Favorite } from '@mui/icons-material'

export default function HealthMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickExamine = () => {
    handleClose()
    window.open('https://examine.com/')
  }

  const handleClickThriva = () => {
    handleClose()
    window.open('https://thriva.co/')
  }

  const handleClick23andMe = () => {
    handleClose()
    window.open('https://www.23andme.com/')
  }

  const handleClickVitaDao = () => {
    handleClose()
    window.open('https://vitadao.com/')
  }

  const handleClickRhondaPatrick = () => {
    handleClose()
    window.open('https://www.foundmyfitness.com/episodes')
  }

  const handleClickPeterAttia = () => {
    handleClose()
    window.open('https://peterattiamd.com/podcast/archive/')
  }

  const handleClickHubermanLabs = () => {
    handleClose()
    window.open('https://hubermanlab.com/')
  }

  return (
    <div>
      <IconButton
        id="health-button"
        title="Health"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Favorite />
      </IconButton>
      <Menu
        id="health-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'icon-button',
        }}
      >
        <MenuItem onClick={handleClickExamine}>
          <Typography variant="subtitle2">Examine - Nutritional Information</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickThriva}>
          <Typography variant="subtitle2">Thriva - Comprehensive Blood Panel</Typography>
        </MenuItem>
        <MenuItem onClick={handleClick23andMe}>
          <Typography variant="subtitle2">23andMe - Genetic Information</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickVitaDao}>
          <Typography variant="subtitle2">VitaDao</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickRhondaPatrick}>
          <Typography variant="subtitle2">Rhonda Patrick - Found My Fitness</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickPeterAttia}>
          <Typography variant="subtitle2">Peter Attia - Archive</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickHubermanLabs}>
          <Typography variant="subtitle2">Huberman Labs</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}