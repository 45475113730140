import * as React from 'react'
import { IconButton,  Menu, MenuItem, Typography, Divider } from '@mui/material'
import { Psychology } from '@mui/icons-material'

export default function HealthMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickGoogleScholar = () => {
    handleClose()
    window.open('https://scholar.google.com/')
  }

  const handleClickSciHub = () => {
    handleClose()
    window.open('https://sci-hub.se/')
  }

  const handleClickStackOverflow = () => {
    handleClose()
    window.open('https://stackoverflow.com/')
  }

  const handleClickWolframAlpha = () => {
    handleClose()
    window.open('https://www.wolframalpha.com/')
  }

  const handleClickOpenAI = () => {
    handleClose()
    window.open('https://openai.com/research/')
  }

  return (
    <div>
      <IconButton
        id="mind-button"
        title="Mind"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Psychology />
      </IconButton>
      <Menu
        id="mind-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'icon-button',
        }}
      >
        <MenuItem onClick={handleClickGoogleScholar}>
          <Typography variant="subtitle2">Google Scholar</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickSciHub}>
          <Typography variant="subtitle2">Sci Hub</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickStackOverflow}>
          <Typography variant="subtitle2">Stack Overflow</Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClickWolframAlpha}>
          <Typography variant="subtitle2">Wolfram Alpha</Typography>
        </MenuItem>
        <MenuItem onClick={handleClickOpenAI}>
          <Typography variant="subtitle2">OpenAI</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}