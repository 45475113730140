/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useSpring, animated } from "react-spring"
import {
  Tooltip,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  Typography,
  Button,
  IconButton,
  Dialog,
  Box,
  Fade,
} from '@mui/material'
import { QrCode2, Whatshot, HorizontalRule } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { StaticQuery, graphql } from "gatsby"
import { issMobile as mobileOption } from "react-device-detect"
// import { Navigation } from '.'

import ObjectiveModal from "./ObjectiveModal"
import DeFiMenu from "./DeFiMenu"
import PakMenu from "./PakMenu"
import HealthMenu from "./HealthMenu"
import MindMenu from "./MindMenu"
import AltTwo from "./alt-two.mp4"
import Cube from "./aCube.mp4"
import Mercury from "./mercury.gif"
import QRCode from '../../images/qrcode-29_12_21.png'

// Styles
import "../../styles/app.css"

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(140px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const theme = createTheme({
  typography: {
    fontSize: 16,
  },
  palette: {
    mode: `dark`, 
    primary: {
      main: grey[`50`],
    },
    secondary: {
      main: grey.A200,
    },
  },
  overrides: {
    Tooltip: {
      tooltip: {
        fontSize: `1.25rem`,
      },
    },
  },
})

function QRCodeDialog(props) {
  const { onClose, open } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <img src={QRCode} />
    </Dialog>
  )
}

function BurnDialog(props) {
  const { onClose, open } = props

  const videoRef = useRef()

  const handleOnClick = () => {
    window.open(`https://etherscan.io/address/0x000000000000000000000000000000000000dead`)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <video
        id="4580"
        title="A cube that can't be owned."
        autoPlay
        loop
        ref={videoRef}
        onClick={handleOnClick}
        style={{
          cursor: `pointer`,                         
          maxHeight: `36rem`,
          maxWidth: `80vw`,
        }}
      >
        <source src={Cube} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Typography
        variant="subtitle1"
        component="a"
        target="_blank"
        href="https://opensea.io/collection/the-fungible-by-pak"
        sx={{
          '&:hover': {
            opacity: 0.8,
            textDecoration: `none`,
          },
          position: `absolute`,
          color: `white`,
          opacity: 0.4,
          bottom: 0,
          transform: `translate(12px,-8px)`,
          '& > code': {
            fontWeight: `bold`,
          },
          transition: `all ease-in 0.16s`,
        }}
      >
        The Fungible: A Cube <code>#4580</code>
      </Typography>
    </Dialog>
  )
}

// Update blog post #1

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
  const [copied, setCopied] = React.useState(false)
  const [properties, api] = useSpring(() => {
    return {
      xys: [0, 0, 1],
      config: {
        mass: 5,
        tension: 350,
        friction: 40,
      },
    }
  }
  )

  const site = data.allGhostSettings.edges[0].node

  const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
  const instagramUrl = `https://www.instagram.com/developed.life/`
  const mediumUrl = `	https://medium.com/@developed.life`
  const [openQRDialog, setOpenQRDialog] = React.useState(false)
  const [openBurnDialog, setOpenBurnDialog] = React.useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false)

  const handleOpenQRDialog = () => {
    setOpenQRDialog(true)
  }

  const handleCloseQRDialog = () => {
    setOpenQRDialog(false)
  }

  const handleOpenBurnDialog = () => {
    setOpenBurnDialog(true)
  }

  const handleCloseBurnDialog = () => {
    setOpenBurnDialog(false)
  }

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [copied])

  const isMobile = useMemo(() => mobileOption, [mobileOption])

  const videoRef = useRef()
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.666
  }

  const handleLoaded = () => {
    setTimeout(() => {
      setIsVideoLoaded(true)
    }, [200])
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Helmet>
          <html lang={site.lang} />
          <style type="text/css">{`${site.codeinjection_styles}`}</style>
          <body className={bodyClass} />
        </Helmet>

        <div className="viewport" style={{ display: isVideoLoaded ? `inherit` : `none` }}>
          <div className="viewport-top">
            {/* The main header section on top of the screen */}
            <header
              className="site-head"
              style={{
                ...(site.cover_image && {
                  background: `#000000`,
                }),
              }}
            >
              <div className="container">
                <div className="site-mast">
                  <div className="site-mast-left" style={{ zIndex: 1 }}>
                    {/* TODO: Reintroduce navigation elements once you have articles */}
                    {/* <Navigation data={site.navigation} navClass="site-nav-item" /> */}
                    <Tooltip title={<Typography variant="subtitle2">Coming soon</Typography>} TransitionComponent={Fade}>
                      <a
                        href={mediumUrl}
                        className="site-nav-item"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          title="Medium"
                          className="site-nav-icon"
                          src="/images/icons/medium.svg"
                          alt="Medium"
                        />
                      </a>
                    </Tooltip>
                  </div>
                  <div className="site-mast-right" style={{ zIndex: 1 }}>
                    {site.twitter && (
                      <a
                        href={twitterUrl}
                        className="site-nav-item"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          title="Twitter"
                          className="site-nav-icon"
                          src="/images/icons/twitter.svg"
                          alt="Twitter"
                        />
                      </a>
                    )}
                    <a
                      href={instagramUrl}
                      className="site-nav-item"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        title="Instagram"
                        className="site-nav-icon"
                        src="/images/icons/instagram.svg"
                        alt="Instagram"
                      />
                    </a>
                  </div>
                </div>

                {isHome ? (
                  <div
                    className="site-banner"
                    style={{
                      display: `flex`,
                      justifyContent: `center`,
                      alignItems: `center`,
                      flexDirection: `column`,
                      spaceBetween: `justify`,
                    }}
                  >
                    <animated.svg
                      className="site-logo"
                      alt="developed.life"
                      src={site.logo}
                      style={{
                        height: !isMobile ? `120px` : `80px`,
                        zIndex: 1,
                        marginBottom: !isMobile ? `2rem` : 0,
                        transform: properties.xys.to(trans),
                        willChange: `transform`,
                      }}
                      onMouseMove={({ clientX: x, clientY: y }) => {
                        api.start({ xys: calc(x, y) })
                      }}
                      onMouseLeave={() => api.start({ xys: [0, 0, 1] })}
                      width="280"
                      height="280"
                      viewBox="0 0 280 280"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M140 280C217.32 280 280 217.32 280 140C280 121.155 276.277 103.179 269.525 86.7692C269.729 93.7921 269.741 146.257 223.997 134.234C174.999 121.355 140.5 193 140.5 193C140.5 193 105.5 121 56.5 134C7.89124 146.896 10.9475 86.4772 10.9994 85.5116C3.91659 102.259 0 120.672 0 140C0 217.32 62.6801 280 140 280ZM134.049 0.124193C136.085 0.0451928 138.152 0.00331509 140.249 0.000216148C140.166 7.2069e-05 140.083 0 140 0C138.006 0 136.022 0.0416723 134.049 0.124193Z"
                        fill="white"
                      />
                      <path
                        d="M139.889 0.5C139.889 0.5 88.7779 138.5 139.889 138.5C191 138.5 139.889 0.5 139.889 0.5Z"
                        fill="white"
                      />
                    </animated.svg>
                    <div style={{ paddingTop: `2rem` }}>
                      <ObjectiveModal />
                    </div>
                  </div>
                ) : null}
                {isHome && (!isMobile ? (
                  <div
                    className="site-video"
                    style={{
                      display: `flex`,
                      marginBottom: `auto`,
                    }}
                  >
                    <video
                      autoPlay
                      loop
                      muted
                      ref={videoRef}
                      onCanPlay={() => setPlayBack()}
                      onLoadedData={handleLoaded}
                      style={{
                        height: `auto`,
                        marginLeft: `auto`,
                        marginRight: `auto`,
                        maxHeight: `36vh`,
                        maxWidth: `80vw`,
                      }}
                    >
                      <source src={AltTwo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                ) : (
                  <div
                    className="site-video"
                    style={{
                      display: `flex`,
                      margin: `auto`,
                      maxWidth: `40vw`,
                    }}
                  >
                    <img
                      src={Mercury}
                      alt="Origin"
                      style={{
                        maxHeight: `16rem`,
                        marginLeft: `auto`,
                        marginRight: `auto`,
                      }}
                      onLoad={handleLoaded}
                    />
                  </div>
                ))}
              </div>
            </header>

            <main className="site-main">
              {/* All the main content gets inserted here, index.js, post.js */}
              {children}
            </main>

            <QRCodeDialog
              open={openQRDialog}
              onClose={handleCloseQRDialog}
            />
            <BurnDialog
              open={openBurnDialog}
              onClose={handleCloseBurnDialog}
            />
          </div>

          <div className="viewport-bottom">
            {/* The footer at the very bottom of the screen */}
            <footer className="site-foot">
              <div className="site-foot-nav container">
                <Box className="site-foot-nav-left" display="flex" alignItems="center" sx={{ '& > *': { mr: 1 } }}>
                  <Button
                    variant="text"
                    title="0x0...0dEaD"
                    sx={{ borderRadius: 24 }}
                    onClick={handleOpenBurnDialog}
                    startIcon={
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.4">
                          <path d="M11.9975 1.00879L5.25122 12.2038L11.9975 9.13758V1.00879Z" fill="white"/>
                          <path d="M11.9975 9.13745L5.25122 12.2037L11.9975 16.1925V9.13745Z" fill="#F1F1F1"/>
                          <path d="M18.7452 12.2038L11.9976 1.00879V9.13758L18.7452 12.2038Z" fill="#F1F1F1"/>
                          <path d="M11.9976 16.1925L18.7452 12.2037L11.9976 9.13745V16.1925Z" fill="#D2D2D2"/>
                          <path d="M5.25122 13.4836L11.9975 22.9911V17.4699L5.25122 13.4836Z" fill="white"/>
                          <path d="M11.9976 17.4699V22.9911L18.7488 13.4836L11.9976 17.4699Z" fill="#F1F1F1"/>
                        </g>
                      </svg>
                    }
                  >
                    <Whatshot color="disabled" />
                  </Button>
                  <PakMenu />
                  <DeFiMenu />
                  <HorizontalRule color="disabled" size="small" />
                  <HealthMenu />
                  <MindMenu />
                </Box>
                <Box className="site-foot-nav-right" display="flex" alignItems="center">
                  <Tooltip
                    title={
                      <Typography variant="subtitle2">
                        {copied ? `copied` : `All donations welcome 💫`}
                      </Typography>
                    }
                    placement="top"
                    TransitionComponent={Fade}
                  >
                    <Button
                      className="donate"
                      variant="text"
                      sx={{ borderRadius: 24 }}
                      startIcon={
                        <svg height="24" width="24" viewBox="0 0 24 24" fill="none">
                          <g transform="translate(0.00630876,-0.00301984),scale(0.375)">
                            <path
                              fill="#f7931a"
                              d="m63.033,39.744c-4.274,17.143-21.637,27.576-38.782,23.301-17.138-4.274-27.571-21.638-23.295-38.78,4.272-17.145,21.635-27.579,38.775-23.305,17.144,4.274,27.576,21.64,23.302,38.784z"
                            />
                            <path
                              fill="#FFF"
                              d="m46.103,27.444c0.637-4.258-2.605-6.547-7.038-8.074l1.438-5.768-3.511-0.875-1.4,5.616c-0.923-0.23-1.871-0.447-2.813-0.662l1.41-5.653-3.509-0.875-1.439,5.766c-0.764-0.174-1.514-0.346-2.242-0.527l0.004-0.018-4.842-1.209-0.934,3.75s2.605,0.597,2.55,0.634c1.422,0.355,1.679,1.296,1.636,2.042l-1.638,6.571c0.098,0.025,0.225,0.061,0.365,0.117-0.117-0.029-0.242-0.061-0.371-0.092l-2.296,9.205c-0.174,0.432-0.615,1.08-1.609,0.834,0.035,0.051-2.552-0.637-2.552-0.637l-1.743,4.019,4.569,1.139c0.85,0.213,1.683,0.436,2.503,0.646l-1.453,5.834,3.507,0.875,1.439-5.772c0.958,0.26,1.888,0.5,2.798,0.726l-1.434,5.745,3.511,0.875,1.453-5.823c5.987,1.133,10.489,0.676,12.384-4.739,1.527-4.36-0.076-6.875-3.226-8.515,2.294-0.529,4.022-2.038,4.483-5.155zm-8.022,11.249c-1.085,4.36-8.426,2.003-10.806,1.412l1.928-7.729c2.38,0.594,10.012,1.77,8.878,6.317zm1.086-11.312c-0.99,3.966-7.1,1.951-9.082,1.457l1.748-7.01c1.982,0.494,8.365,1.416,7.334,5.553z"
                            />
                          </g>
                        </svg>
                      }
                      onClick={() => {
                        navigator.clipboard.writeText(`bc1qfnavzt8rdwgdmgydecq6cvlzu38hvyt5zysvqw`)
                        setCopied(true)
                      }}
                    >
                      <Box
                        alt="bc1qfnavzt8rdwgdmgydecq6cvlzu38hvyt5zysvqw"
                        sx={{
                          textTransform: `none`,
                          fontSize: !isMobile ? `inherit` : `1rem`,
                        }}
                      >
                        bc1qfn...zysvqw
                      </Box>
                    </Button>
                  </Tooltip>
                  <IconButton onClick={handleOpenQRDialog} sx={{ ml: 0.5 }} title="QR Code">
                    <QrCode2 />
                  </IconButton>
                </Box>
              </div>
            </footer>
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  isHome: PropTypes.bool,
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
      query GhostSettings {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
        file(relativePath: { eq: "ghost-icon.png" }) {
          childImageSharp {
            fixed(width: 30, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <DefaultLayout data={data} {...props} />}
  />
)

export default DefaultLayoutSettingsQuery
