import React from "react"
import {
  Tooltip,
  IconButton,
  Box,
  Dialog,
  AppBar,
  Toolbar,
  Slide,
  Container,
} from "@mui/material"
 import { InfoOutlined, Visibility, Close } from "@mui/icons-material"
import { isMobile } from "react-device-detect"

const wrapperProps = {
  px: 1.5,
  pb: 1.5,
  sx: { '& li': { fontSize: 12 }, '& h4': { mt: 1.5, color: `white` }},
}

const contentMathematics = (
  <Box {...wrapperProps}>
    <h4>Specialisations</h4>
    <ul>
      <li>Information networks</li>
      <li>Public blockchains</li>
      <li>Game theory</li>
      <li>Artificial intelligence</li>
      <li>Crowd sourced intelligence</li>
      <li>Software development</li>
      <li>Cyber security</li>
    </ul>
  </Box>
)

const contentArt = (
  <Box {...wrapperProps}>
    <h4>Specialisations</h4>
    <ul>
      <li>Generative</li>
      <li>Computational creativity</li>
      <li>Human interface design</li>
    </ul>
  </Box>
)

const contentEngineering = (
  <Box {...wrapperProps}>
    <h4>Specialisations</h4>
    <ul>
      <li>3D printing</li>
      <li>Augmented reality</li>
    </ul>
  </Box>
)

const contentMolecularBiology = (
  <Box {...wrapperProps}>
    <h4>Specialisations</h4>
    <ul>
      <li>Genomics</li>
      <li>Microbiome</li>
      <li>Metabolism</li>
      <li>Longevity</li>
    </ul>
  </Box>
)

const contentPhysiology = (
  <Box {...wrapperProps}>
    <h4>Specialisations</h4>
    <ul>
      <li>Kinesiology</li>
    </ul>
  </Box>
)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ObjectiveModal() {
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const body = (
    <React.Fragment>
      <h2 id="simple-modal-title">GENESIS</h2>
      <p id="simple-modal-description">We are an idea, we are emergant.</p>
      <p>
        A hive intelligence powered by an anonymous collective, tasked with perpetually
        cultivating debate and information dissemination on the following fields:
      </p>
      <h4>Fields</h4>
      <ul>
        <li>
          Mathematics{" "}
          <Tooltip title={contentMathematics}>
            <IconButton size="medium">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </li>
        <li>
          Art{" "}
          <Tooltip title={contentArt}>
            <IconButton size="medium">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </li>
        <li>
          Engineering{" "}
          <Tooltip title={contentEngineering}>
            <IconButton size="medium">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </li>
        <li>
          Molecular Biology{" "}
          <Tooltip title={contentMolecularBiology}>
            <IconButton size="medium">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </li>
        <li>
          Physiology{" "}
          <Tooltip title={contentPhysiology}>
            <IconButton size="medium">
              <InfoOutlined />
            </IconButton>
          </Tooltip>
        </li>
      </ul>
      <h4>Mantra</h4>
      <p>
        Accessible to anyone with an internet connection, we practise honest knowledge exchange.
        To become a contributing node, make your value known and you’ll find out.
      </p>
      <h4>Dao</h4>
      <p>
        Continuous self iteration. As the network grows we will seek new fields and grow the
        foundational roots. The journey is not a finite one.
      </p>
    </React.Fragment>
  )

  return (
    <div>
      {!isMobile && (
        <div onClick={handleOpen}>
          <Visibility alt="Objectives" style={{ cursor: `pointer`, fontSize: `3rem` }} />
        </div>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Container maxWidth="md">
          {body}
        </Container>
      </Dialog>
    </div>
  )
}
